import React, { useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import size from 'lodash/size'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import Touchable from 'Components/touchables/Touchable'
import Tooltip from 'Components/popups/Tooltip/Tooltip'
import { fadeUp } from 'Utils/animations'
import SvgIcon from 'Components/SvgIcon/SvgIcon'
import LogoWhite from 'Assets/logo-white.inline.svg'
import { MenuItem } from 'Components/layout/Menu'
import { PATHS } from 'Utils/routing'
import styles from './Footer.module.scss'

export type FooterProps = {
  className?: string
}

const INN = '7725265977'
const KPP = '772801001'
const OGRN = '1157746222590'

const Footer = (props: FooterProps) => {
  const { className = '' } = props

  const [t] = useTranslation()

  const [copyright, setCopyright] = useState(t('footer.copyright'))
  const [copyrightHint, setCopyrightHint] = useState(t('footer.copyright_hint'))

  const menu: Array<MenuItem> = t('footer.menu', { returnObjects: true })

  useLayoutEffect(() => {
    // [HINT] Чтобы год был всегда актуальным без пересборки
    setCopyright((p) => p!.replace('%YEAR%', new Date().getFullYear().toString()))
    setCopyrightHint((p) => {
      return p!.replace('%INN%', INN).replace('%KPP%', KPP).replace('%OGRN%', OGRN)
    })
  }, [])

  return (
    <BaseContainer
      className={classNames([styles.root, className])}
      contentClassName={styles.container}
      vertical="xs"
    >
      <Row vertical="bottom" horizontal="spaced" className={styles.row}>
        <div className={styles.left}>
          <Touchable link={PATHS.home}>
            <div {...fadeUp()}>
              <SvgIcon Icon={LogoWhite} className={styles.logo} />
            </div>
          </Touchable>
          <Tooltip
            placement="bottom-start"
            title={copyrightHint ?? ''}
            textAlign="left"
            isInvert
          >
            <div className={styles.copyright} {...fadeUp({ index: 1 })}>
              <Text variant="xs" className={styles.copyrightText}>
                {copyright}
              </Text>
            </div>
          </Tooltip>
        </div>
        <Row wrap className={styles.menu}>
          {menu.map(({ id, title, link }, index) => (
            <Touchable key={id} link={link} className={styles.menuItem}>
              <div {...fadeUp({ index: index + 1 })}>
                <Text variant="sm" className={styles.menuText}>
                  {title}
                </Text>
              </div>
            </Touchable>
          ))}
        </Row>
        <Row
          horizontal="right"
          className={styles.email}
          {...fadeUp({ index: Math.ceil(size(menu) / 2) + 1 })}
        >
          <Text className={styles.emailLabel} variant="xs">
            {`${t('emailLabel')}: `}
          </Text>
          <Touchable href={`mailto:${t('email')}`}>
            <Text className={styles.emailValue} variant="xs">
              {t('email')}
            </Text>
          </Touchable>
        </Row>
      </Row>
    </BaseContainer>
  )
}

export default Footer
